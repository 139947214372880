import { graphql } from 'gatsby'
import { Headline } from '@dfds-ui/react-components'

import { FlexCard } from '../Card'
import { Markdown } from '../Markdown'
import { gridMap } from '../../utils'
import { EntryLink } from '../EntryLink'

const PageContentTextSection = (props) => {
  const {
    backgroundColor,
    blockWidth,
    showTitle,
    contentRef,
    noPadding,
    noSpacing,
  } = props
  const cardWidth =
    typeof blockWidth === 'object' ? blockWidth : gridMap[blockWidth]

  return contentRef ? (
    <FlexCard
      width={cardWidth}
      backgroundColor={backgroundColor}
      action={
        contentRef?.linksCollection?.items.length > 0
          ? contentRef?.linksCollection?.items.map((item) => {
              return <EntryLink {...item} key={item.sys.id} />
            })
          : null
      }
      noPadding={noPadding}
      noSpacing={noSpacing || false}
    >
      {showTitle && <Headline as={'h2'}>{contentRef.title}</Headline>}
      <Markdown source={contentRef.text} />
    </FlexCard>
  ) : null
}

export const query = graphql`
  fragment PageContentTextSection on contentful_PageContentTextSection {
    __typename
    sys {
      id
    }
    backgroundColor
    showTitle
    blockWidth
    contentRef {
      title
      text
      linksCollection(limit: 4) {
        items {
          ...ItemLink
        }
      }
    }
  }
`

export default PageContentTextSection
